<template>
  <b-container class="page-container">
    <bread-crumbs currentpage="Reserveren" />
    <h1>Reserveren</h1>
    <hr />
    <b-form @submit.prevent="checkout">
      <b-row>
        <b-col md="7" sm="12">
          <h3>Gegevens van de besteller</h3>

          <b-card class="mt-4">
            <b-row>
              <b-col cols="6" class="pb-3">
                <label>Voornaam</label>
                <b-form-input
                  v-model="orderer.firstname"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>Achternaam</label>
                <b-form-input
                  v-model="orderer.lastname"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="9" class="pb-3">
                <label>Straatnaam</label>
                <b-form-input v-model="orderer.street" disabled></b-form-input>
              </b-col>
              <b-col cols="3">
                <label>Huisnr.</label>
                <b-form-input
                  v-model="orderer.houseNumber"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="4" class="pb-3">
                <label>Postcode</label>
                <b-form-input v-model="orderer.postal" disabled></b-form-input>
              </b-col>
              <b-col cols="8">
                <label>Gemeente</label>
                <b-form-input v-model="orderer.city" disabled></b-form-input>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>E-mailadres</label>
                <b-form-input v-model="orderer.email" disabled></b-form-input>
              </b-col>
              <b-col cols="6">
                <label>Telefoonnummer</label>
                <b-form-input v-model="orderer.tel" disabled></b-form-input>
              </b-col>
            </b-row>

            <router-link to="/account" class=" pt-2"
              >Pas je account aan om deze gegevens te wijzigen ></router-link
            >
          </b-card>
        </b-col>
        <b-col md="5" sm="12">
          <h3>Overzicht</h3>
          <b-card
            ><div>
              <strong>Afhaallocatie</strong>
              <p>{{ cartCache.WarehouseID }}</p>
            </div>
            <div>
              <strong>Afhaaldatum</strong>
              <p>
                {{ cartCache.DateTimeBusinessStart | moment('DD/MM/YYYY') }}
              </p>
            </div>
            <div>
              <strong>Terugbrengdatum</strong>
              <p>
                {{ cartCache.DateTimeExpectedEnd | moment('DD/MM/YYYY') }}
              </p>
            </div>
          </b-card>
          <div class="w-100">
            <router-link to="/cart" class="float-right pt-2 pb-2"
              >Pas afhaallocatie en ontleendata aan ></router-link
            >
          </div>
          <div class="mt-2">
            <b-table :items="items" :fields="fields"
              ><template v-slot:cell(total)="data">
                <div class="form-group text-right">
                  &euro;
                  {{
                    (Math.round(data.item.totalIncVAT * 100) / 100)
                      .toFixed(2)
                      .replace('.', ',')
                  }}
                </div>
              </template></b-table
            >
            <b-row>
              <b-col cols="9" class="text-right cart-total-price">
                <p>
                  <strong>BTW:</strong>
                </p>
              </b-col>
              <b-col cols="3" class="text-right">
                <p>
                  &euro;
                  {{
                    cartCache.TotalVAT.toFixed(2)
                      .toString()
                      .replace('.', ',')
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="9" class="text-right cart-total-price">
                <p>
                  <strong>Totaalbedrag:</strong>
                </p>
              </b-col>
              <b-col cols="3" class="text-right">
                <p>
                  &euro;
                  {{
                    cartCache.TotalIncVAT.toFixed(2)
                      .toString()
                      .replace('.', ',')
                  }}
                </p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-card class="mb-4 mt-2">
        <div>
          <label
            >Omschrijving activiteit: <span class="text-danger">*</span></label
          >
          <b-form-textarea
            v-model="CUST_ActivityDescription"
            :required="true"
          />
        </div>
        <div class="mt-2">
          <label>Opmerkingen:</label> <b-form-textarea v-model="notes" />
        </div>

        <hr />
        <b-button
          class="float-right btn"
          :class="{ 'button-disabled': checkoutLoading }"
          variant="primary"
          type="submit"
          >Aanvraag bevestigen&nbsp;<font-awesome-icon
            v-if="checkoutLoading"
            :disabled="checkoutLoading"
            class="fa-spin"
            far
            :icon="['fad', 'spinner']"
        /></b-button>
        <h2 class="pt-2">Aanvraag om te bevestigen?</h2>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import { getCart, checkout } from '@/services/CartService'
import moment from 'moment'
export default {
  data() {
    return {
      checkoutLoading: false,
      orderer: {
        firstname: '',
        lastname: '',
        street: '',
        houseNumber: '',
        postal: '',
        city: '',
        email: '',
        tel: ''
      },
      eventStartHourOptions: [
        { value: '1:00', text: '1:00' },
        { value: '2:00', text: '2:00' },
        { value: '3:00', text: '3:00' },
        { value: '4:00', text: '4:00' },
        { value: '5:00', text: '5:00' },
        { value: '6:00', text: '6:00' },
        { value: '7:00', text: '7:00' },
        { value: '8:00', text: '8:00' },
        { value: '9:00', text: '9:00' },
        { value: '10:00', text: '10:00' },
        { value: '11:00', text: '11:00' },
        { value: '12:00', text: '12:00' },
        { value: '13:00', text: '13:00' },
        { value: '14:00', text: '14:00' },
        { value: '15:00', text: '15:00' },
        { value: '16:00', text: '16:00' },
        { value: '17:00', text: '17:00' },
        { value: '18:00', text: '18:00' },
        { value: '19:00', text: '19:00' },
        { value: '20:00', text: '20:00' },
        { value: '21:00', text: '21:00' },
        { value: '22:00', text: '22:00' },
        { value: '23:00', text: '23:00' },
        { value: '24:00', text: '24:00' }
      ],
      reserverationDetails: {
        eventDescription: '',
        eventStartDate: '',
        eventStartHour: '',
        eventAdres: '',
        expectedRise: '',
        files: '',
        extraInfo: ''
      },
      notes: '',
      CUST_ActivityDescription: '',
      items: [],
      retrievalDate: '',
      returnDate: '',
      warehouse: '',
      fields: [
        {
          key: 'item',
          label: 'Item'
        },
        {
          key: 'cartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'amount',
          label: 'Aantal',
          class: 'text-center'
        },
        {
          key: 'total',
          class: 'pr-0 text-right',
          label: 'Totaal'
        }
      ]
    }
  },
  computed: {
    cartCache: function() {
      return this.$store.getters.cartCache
    },
    subTotal: function() {
      const sumPrice = this.items
        .map(item => item.total)
        .reduce((a, c) => {
          return a + c
        })

      return (Math.round(sumPrice * 100) / 100).toFixed(2).replace('.', ',')
    },
    user: function() {
      return this.$store.getters.user
    }
  },
  async beforeMount() {
    this.items = this.cartCache.CartItems
    const cart = await getCart()
    this.items = cart

    this.retrievalDate = moment(
      this.$store.getters.cartCache.DateTimeBusinessStart
    ).format('DD-MM-YYYY')
    this.returnDate = moment(
      this.$store.getters.cartCache.DateTimeBusinessEnd
    ).format('DD-MM-YYYY')
    this.warehouse = this.cartCache.WarehouseID
  },

  created() {
    this.orderer.houseNumber = ''
    this.orderer.city = ''
    this.orderer.postal = ''
    this.orderer.email = this.user.Email
    this.orderer.firstname = this.user.FirstName
    this.orderer.lastname = this.user.LastName
    this.orderer.tel = this.user.Tel
    this.orderer.street = ''
  },

  methods: {
    checkout: async function() {
      this.checkoutLoading = true

      const reservation = await checkout({
        reserverationDetails: this.reserverationDetails,
        notes: this.notes,
        CUST_ActivityDescription: this.CUST_ActivityDescription
      })
      this.$store.commit('setLatestReservation', {
        reservation
      })
      this.items = await getCart({ notes: this.notes })


      if (this.items.length === 0) {
       this.$store.commit('rentalInformation/reset')

      }

      this.$router.push({
        name: 'reservation-confirmation',
        props: { fields: this.fields, reservation }
      })

      this.checkoutLoading = false
    }
  }
}
</script>
<style>
.button-disabled {
  pointer-events: none;
}
</style>
